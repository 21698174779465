import { setupNextraPage } from 'nextra/setup-page'
import __nextra_layout from 'nextra-theme-docs'
import __nextra_themeConfig from '/vercel/path0/website/theme.config.jsx'

import 'nextra-theme-docs/style.css'
/*@jsxRuntime automatic @jsxImportSource react*/
import {useMDXComponents as _provideComponents} from "nextra/mdx";
export const __toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    blockquote: "blockquote",
    ul: "ul",
    li: "li",
    code: "code"
  }, _provideComponents(), props.components);
  return <><_components.p><_components.a href="https://react-rx.dev/"><_components.img src="https://user-images.githubusercontent.com/81981/194187624-9abd09da-bf03-4886-b512-78c1f22fc2de.png" alt="react-rx-some-smaller" /></_components.a></_components.p>{"\n"}<_components.blockquote>{"\n"}<_components.p>{"Hooks for combining React with RxJS Observables"}</_components.p>{"\n"}</_components.blockquote>{"\n"}<_components.p>{"Features:"}</_components.p>{"\n"}<_components.ul>{"\n"}<_components.li>{"Works well with "}<_components.code>{"Observables"}</_components.code>{" emitting values synchronously. You don't pay the re-render-on-mount tax."}</_components.li>{"\n"}<_components.li>{"Lightweight. Implemented on top of a small React Hook based core."}</_components.li>{"\n"}<_components.li>{"Full TypeScript support."}</_components.li>{"\n"}</_components.ul></>;
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? <MDXLayout {...props}><_createMdxContent {...props} /></MDXLayout> : _createMdxContent(props);
}



const __nextraPageOptions = {
  MDXContent,
  pageOpts: {"filePath":"src/pages/index.md","route":"/","timestamp":1722268467000,"pageMap":[{"kind":"Meta","data":{"index":"Introduction","guide":"Guide","reference":"API","examples":"Examples"}},{"kind":"Folder","name":"examples","route":"/examples","children":[{"kind":"Meta","data":{"simple":"Simple","counters":"Advanced counters","errors":"Error handling","todo-app":"Todo app","animation":"Animation","context":"React context","sync":"Sync rendering","event-handlers":"Event Handlers","fetch":"Fetch","form-data":"Form Data","search":"Search","use-observable":"Use Observable"}},{"kind":"MdxPage","name":"animation","route":"/examples/animation"},{"kind":"MdxPage","name":"context","route":"/examples/context"},{"kind":"MdxPage","name":"counters","route":"/examples/counters"},{"kind":"MdxPage","name":"errors","route":"/examples/errors"},{"kind":"MdxPage","name":"event-handlers","route":"/examples/event-handlers"},{"kind":"MdxPage","name":"fetch","route":"/examples/fetch"},{"kind":"MdxPage","name":"form-data","route":"/examples/form-data"},{"kind":"MdxPage","name":"search","route":"/examples/search"},{"kind":"MdxPage","name":"simple","route":"/examples/simple"},{"kind":"MdxPage","name":"sync","route":"/examples/sync"},{"kind":"MdxPage","name":"todo-app","route":"/examples/todo-app"},{"kind":"MdxPage","name":"use-observable","route":"/examples/use-observable"}]},{"kind":"MdxPage","name":"examples","route":"/examples"},{"kind":"MdxPage","name":"guide","route":"/guide"},{"kind":"MdxPage","name":"index","route":"/"},{"kind":"MdxPage","name":"reference","route":"/reference"}],"flexsearch":{"codeblocks":true},"title":"Index",headings:__toc},
  pageNextRoute: "/",
  nextraLayout: __nextra_layout,themeConfig: __nextra_themeConfig
}
if (process.env.NODE_ENV !== 'production') {
  __nextraPageOptions.hot = module.hot
  __nextraPageOptions.pageOptsChecksum = ''
}
if (typeof window === 'undefined') __nextraPageOptions.dynamicMetaModules = []

export default setupNextraPage(__nextraPageOptions)